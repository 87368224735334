import React from 'react'
import { LayoutIR } from '../../../components/organisms/Layout/LayoutIR'
import { SEOIR } from '../../../components/data/SEO'
import { ENGLISH_TITLE } from '../../../components/data/English'

const Page = () => {
  return (
    <LayoutIR
      heading="株式事務手続き"
      headingEnglish={ENGLISH_TITLE.ProceduresForStockRelatedMatters}
      BreadcrumbData={[
        { title: '株主・投資家の皆さまへ', url: '/ir' },
        { title: '株式・株主情報', url: '/ir/stock' },
        { title: '株式事務手続き', url: '/' },
      ]}
    >
      <div className="pc:pt-12 pc:px-8 pc:pb-12 bg-white">
        <div className="flex pr-6 pl-4 py-6 pc:py-7 border-t border-b border-border10">
          <p className="text-sm pc:text-md w-28 pc:w-48 leading-normal font-bold">
            証券コード
          </p>
          <p className="text-sm pc:text-md leading-normal flex-1">7041</p>
        </div>
        <div className="flex pr-6 pl-4 py-6 pc:py-7 border-b border-border10">
          <p className="text-sm pc:text-md w-28 pc:w-48 leading-normal font-bold">
            上場証券取引所
          </p>
          <p className="text-sm pc:text-md leading-normal flex-1">
            東京証券取引所グロース市場
          </p>
        </div>
        <div className="flex pr-6 pl-4 py-6 pc:py-7 border-b border-border10">
          <p className="text-sm pc:text-md w-28 pc:w-48 leading-normal font-bold">
            事業年度
          </p>
          <p className="text-sm pc:text-md leading-normal flex-1">
            毎年10月1日から翌年9月30日まで
          </p>
        </div>
        <div className="flex pr-6 pl-4 py-6 pc:py-7 border-b border-border10">
          <p className="text-sm pc:text-md w-28 pc:w-48 leading-normal font-bold">
            定時株主総会
          </p>
          <p className="text-sm pc:text-md leading-normal flex-1">
            毎事業年度末日から3ヵ月以内
          </p>
        </div>
        <div className="flex pr-6 pl-4 py-6 pc:py-7 border-b border-border10">
          <p className="text-sm pc:text-md w-28 pc:w-48 leading-normal font-bold">
            基準日
          </p>
          <p className="text-sm pc:text-md leading-normal flex-1">
            毎年9月30日
          </p>
        </div>
        <div className="flex pr-6 pl-4 py-6 pc:py-7 border-b border-border10">
          <p className="text-sm pc:text-md w-28 pc:w-48 leading-normal font-bold">
            剰余金の配当の
            <br />
            基準日
          </p>
          <p className="text-sm pc:text-md leading-normal flex-1">
            毎年3月31日、毎年9月30日
          </p>
        </div>
        <div className="flex pr-6 pl-4 py-6 pc:py-7 border-b border-border10">
          <p className="text-sm pc:text-md w-28 pc:w-48 leading-normal font-bold">
            1単元の株式数
          </p>
          <p className="text-sm pc:text-md leading-normal flex-1">100株</p>
        </div>
        <div className="flex pr-6 pl-4 py-6 pc:py-7 border-b border-border10">
          <p className="text-sm pc:text-md w-28 pc:w-48 leading-normal font-bold">
            株主名簿管理人
          </p>
          <p className="text-sm pc:text-md leading-normal flex-1">
            三菱UFJ信託銀行株式会社
            <br />
            東京都千代田区丸の内一丁目4番5号
          </p>
        </div>
        <div className="flex pr-6 pl-4 py-6 pc:py-7 border-b border-border10">
          <p className="text-sm pc:text-md w-28 pc:w-48 leading-normal font-bold">
            事務取扱場所
          </p>
          <p className="text-sm pc:text-md leading-normal flex-1">
            東京都千代田区丸の内一丁目4番5号
            <br />
            三菱UFJ信託銀行株式会社 証券代行部
          </p>
        </div>
        <div className="flex pr-6 pl-4 py-6 pc:py-7 border-b border-border10">
          <p className="text-sm pc:text-md w-28 pc:w-48 leading-normal font-bold">
            郵便物送付先
            <br />
            および
            <br className="pc:hidden" />
            電話照会先
          </p>
          <p className="text-sm pc:text-md leading-normal flex-1">
            〒137－8081 新東京郵便局私書箱第29号
            <br />
            三菱UFJ信託銀行株式会社 証券代行部
            <br />
            電話：0120-232-711
            <br className="pc:hidden" />
            （フリーダイヤル）
          </p>
        </div>
        <div className="flex pr-6 pl-4 py-6 pc:py-7 border-b border-border10">
          <p className="text-sm pc:text-md w-28 pc:w-48 leading-normal font-bold">
            取次所
          </p>
          <p className="text-sm pc:text-md leading-normal flex-1">
            三菱UFJ信託銀行株式会社 全国各支店
          </p>
        </div>
        <div className="flex pr-6 pl-4 py-6 pc:py-7 border-b border-border10">
          <p className="text-sm pc:text-md w-28 pc:w-48 leading-normal font-bold">
            公告掲載方法
          </p>
          <p className="text-sm pc:text-md leading-normal flex-1">
            電子公告により行います。ただし、事故その他やむを得ない事由によって電子公告による公告をすることができない場合には、日本経済新聞に掲載して行います。
            <br />
            公告掲載URL：https://www.crgh.co.jp
          </p>
        </div>
        <div className="flex pr-6 pl-4 py-6 pc:py-7 border-b border-border10">
          <p className="text-sm pc:text-md w-28 pc:w-48 leading-normal font-bold">
            株主に対する
            <br className="pc:hidden" />
            特典
          </p>
          <p className="text-sm pc:text-md leading-normal flex-1">
            該当事項はありません。
          </p>
        </div>
      </div>
    </LayoutIR>
  )
}

export default Page

/**
 * headタグの内容
 */
/** */
export const Head = () => (
  <SEOIR title="株式事務手続き" url="/ir/stock/procedure" />
)
